import "./Home.css";
import { Row, Col, Card, Image } from "../../bootstrap.js";
import { ResearchButton } from "../Button/ResearchButton.js";
import { Programs } from "../Programs/Programs.js";
import { ContactHome } from "../Contact/ContactHome/ContactHome.js";
import { Highlights } from "../Highlights/Highlights.js";
import { Logos } from "../Logos/Logos.js";
import { MyCarousel } from "../MyCarousel/MyCarousel.js";
import { LinkedInPosts } from "../LinkedInPosts/LinkedInPosts.js";
import { CarouselPub } from "../MyCarousel/CarouselPub.js";
import { HighlightsHome } from "../HighlightsHome/HighlightsHome.js";
import {Embed} from "../LinkedInPosts/Embed.js";
import { useMediaQuery } from "usehooks-ts";
import {Tools} from "../Tools/Tools.js";
import {Tools2} from "../Tools/Tools2.js";

export function Home() {
  const isMobile2 = useMediaQuery("(max-width: 992px)");

  return (
    <div>
      {isMobile2 ? (
        // Mobile version content
        <>
          <section className="cuttingEdgeWrapper">
            <div className="cuttingEdgeCont">
              <h2 className="cuttingEdge">Cutting Edge Research</h2>
              <div className="cuttingEdgeButton">
                <ResearchButton />
              </div>
            </div>
            <div className="imgbg">
              <img
                src="/images/Research/COMBINELabWebsiteHomePageHeaderImage.png"
                alt="lungs&torso"
                className="torso-img"
              />
            </div>
          </section>
          <div className="home">
            <Row className="about sec1">
              <Col xs={12}>
                <header className="headerMolec">
                  <h3>
                    <span className="minkara">MINKARA</span>{" "}
                    <span className="combine">COMBINE</span>{" "}
                    <span className="minkara">LAB</span>
                  </h3>
                  <h4 className="molec">Molecular Detectives</h4>
                </header>
                <p>
                  The Computational Modeling for Biointerface Engineering Laboratory (COMBINE Lab) at Northeastern University utilizes computational techniques to model complex biological interfaces at the molecular level, with the ultimate goal of engineering new therapeutics in the field of bioengineering.
                </p>
              </Col>
              <Row xs={12} md={6} className="homeVid">
                <a
                  href="https://www.youtube.com/watch?v=uQtDc797tf8"
                  className="vidCombineLink"
                >
                  <Image
                    src="/images/HomePics/introCombineVid.jpeg"
                    className="img-fluid vidCombine"
                    alt="Mona sits at a desk in her office."
                  />
                  <i className="bi bi-play-circle playHome"></i>
                </a>
              </Row>
            </Row>
            <section className="sec-bg">
              <Programs />
            </section>

            <section className="toolsSecCont">
              <header className="toolsSec headerContPI">
                <h3 className="redHeaderHome">Featured IN</h3>
              </header>
              <Logos />
            </section>

            <section className="toolsSecCont">
              <Row>
                <Col xs={12} md={6}>
                  <header className="toolsSec">
                    <h3 className="redHeaderHome">Public Impact</h3>
                  </header>
                </Col>
                
              </Row>
              <Row>
                  <MyCarousel />
              </Row>
              <Row className="project-highlight">
                  <header className="toolsSec">
                    <h3 className="redHeaderHome">LinkedIn</h3>
                  </header>
                  <Row xs={12} md={6} className="tools-col">
                  <Embed />
                  </Row>
               </Row>
                <Row className="project-highlight">
                  <header className="toolsSec">
                    <h3 className="redHeaderHome text-right">Project Highlight</h3>
                  </header>
                <Row xs={12} md={6} className="tools-col">
                  <Tools />
                </Row>
              </Row>
            </section>
          </div>
        </>
      ) : (
        // Desktop version content
        <>
          <section className="cuttingEdgeWrapper">
            <div className="cuttingEdgeCont">
              <h2 className="cuttingEdge">Cutting Edge Research</h2>
              <div className="cuttingEdgeButton">
                <ResearchButton />
              </div>
            </div>
            <div className="imgbg">
              <img
                src="/images/Research/COMBINELabWebsiteHomePageHeaderImage.png"
                alt="lungs&torso"
                className="torso-img"
              />
            </div>
          </section>

          {/* Common content for desktop */}
          <div className="home">
            <Row className="about sec1">
              <Col xs={12} md={6}>
                <header className="headerMolec">
                  <h3>
                    <span className="minkara">MINKARA</span>{" "}
                    <span className="combine">COMBINE</span>{" "}
                    <span className="minkara">LAB</span>
                  </h3>
                  <h4 className="molec">Molecular Detectives</h4>
                </header>
                <p>
                  The Computational Modeling for Biointerface Engineering Laboratory (COMBINE Lab) at Northeastern University utilizes computational techniques to model complex biological interfaces at the molecular level, with the ultimate goal of engineering new therapeutics in the field of bioengineering.
                </p>
              </Col>
              <Col xs={12} md={6} className="homeVid">
                <a
                  href="https://www.youtube.com/watch?v=uQtDc797tf8"
                  className="vidCombineLink"
                >
                  <Image
                    src="/images/HomePics/introCombineVid.jpeg"
                    className="img-fluid vidCombine"
                    alt="Mona sits at a desk in her office."
                  />
                  <i className="bi bi-play-circle playHome"></i>
                </a>
              </Col>
            </Row>

            <section className="sec-bg">
              <Programs />
            </section>

            <section className="toolsSecCont">
              <header className="toolsSec headerContPI">
                <h3 className="redHeaderHome">Featured IN</h3>
              </header>
              <Logos />
            </section>

            <section className="toolsSecCont">
              <Row>
                <Col xs={12} md={6}>
                  <header className="toolsSec">
                    <h3 className="redHeaderHome">Public Impact</h3>
                  </header>
                </Col>
                <Col xs={12} md={6} className="project-highlight">
                  <header className="toolsSec">
                    <h3 className="redHeaderHome text-right">Project Highlight</h3>
                  </header>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <MyCarousel />
                  <header className="toolsSec">
                    <h3 className="redHeaderHome">LinkedIn</h3>
                  </header>
                  <Embed />
                </Col>
                <Col xs={12} md={6} className="tools-col">
                  <Tools />
                </Col>
              </Row>
            </section>
          </div>
        </>
      )}
    </div>
  );
}
