
import "./groupMembers.css";
import { Row, Col, Image } from "../../bootstrap.js";
import { useMediaQuery } from "usehooks-ts";

export function GroupMembers({ members }) {
  const isMobile = useMediaQuery("(max-width: 992px)");

  return (
    <section>
      <Row className="group2Row">
        {members.map((member, i) =>
          i > 0 ? (
            isMobile ? (
              /* Mobile Version */
              <div key={i} className="groupContainer">
                <Col xs={12} sm={12} className="groupPic">
                  <Image
                    src={`/images/Group/${member.img}.jpg`}
                    className="img-fluid memberPic"
                    alt={member.alt}
                  />
                </Col>
                <Col xs={12} className="div1">
                  <header>
                    <h4 className="groupName">{member.name}</h4>
                  </header>
                  <div className="positionsGroup">
                    <h5 className="groupTitle">{member.title}</h5>
                    <div className="aboveBorder">
                      <h5 className={member.major ? "groupTitle2" : "education"}>
                        {member.major ? member.major + " Major" : member.education}
                      </h5>
                      <div className="groupSoc">
                        <a href={member.linkedIn}>
                          <i className="bi bi-linkedin"></i>
                        </a>
                      </div>
                    </div>
                    <hr className="hrLine" />
                    <p className="funFact">{member.bio}</p>
                    {member.awards && member.awards.trim() !== "" && (
                      <>
                        <p className="redHeader">Awards</p>
                        <ul className="awards">
                          {member.awards.split('•').map((award, index) =>
                            award.trim() && <li key={index}>{award.trim()}</li>
                          )}
                        </ul>
                      </>
                    )}
                  </div>
                </Col>
              </div>
            ) : (
              /* Desktop Version */
              <div key={i} className="groupContainer">
                <Col xs={12} md={6} lg={4} className="groupPic">
                  <Image
                    src={`/images/Group/${member.img}.jpg`}
                    className="img-fluid memberPic"
                    alt={member.alt}
                  />
                </Col>
                <Col xs={12} md={6} lg={8} className="div1">
                  <header>
                    <h4 className="groupName">{member.name}</h4>
                  </header>
                  <div className="positionsGroup">
                    <h5 className="groupTitle">{member.title}</h5>
                    <div className="aboveBorder">
                      <h5 className={member.major ? "groupTitle2" : "education"}>
                        {member.major ? member.major + " Major" : member.education}
                      </h5>
                      <div className="groupSoc">
                        <a href={member.linkedIn}>
                          <i className="bi bi-linkedin"></i>
                        </a>
                      </div>
                    </div>
                    <hr className="hrLine" />
                    <p className="funFact">{member.bio}</p>
                    {member.awards && member.awards.trim() !== "" && (
                      <>
                        <p className="redHeader">Awards</p>
                        <ul className="awards">
                          {member.awards.split('•').map((award, index) =>
                            award.trim() && <li key={index}>{award.trim()}</li>
                          )}
                        </ul>
                      </>
                    )}
                  </div>
                </Col>
              </div>
            )
          ) : null
        )}
      </Row>
    </section>
  );
}

