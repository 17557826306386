import { Row, Col, Image } from "../../bootstrap.js";
import * as data from "./groupMembersData.js";
import { GroupMembers } from "./GroupMembers.js";
import { GroupMembers2 } from "./GroupMembers2.js";
import { GroupMembersAccess } from "./GroupMembersAccess.js";
import { PrevMembers } from "./PrevMembers.js";
import "./group.css";
import { useMediaQuery } from "usehooks-ts";
import { Mona } from "./Mona.js";

export function Group() {
  const isMobile2 = useMediaQuery("(max-width: 992px)");

  return (
    <div>
      {isMobile2 ? (
        <>
          <div className="groupHero">
            <header>
              <h3 className="labMembersTop">Group</h3>
            </header>
            <Row className="heroGroup">
              <Col>
                <Image
                  src="/images/Group/group4.png"
                  className="img-fluid groupPicHero"
                  alt="Group members all standing in a line with one holding a cake."
                />
              </Col>
            </Row>
          </div>
          <div className="groupContainer">
            <header>
              <h3 className="labMembers">Principal Investigator</h3>
            </header>
            <section>
              <Mona members={data.PI} />
            </section>
            <header>
              <h3 className="labMembers">PhD Group Members</h3>
            </header>
            <section>
              <GroupMembers members={data.PhDStudents} />
            </section>
            <header>
              <h3 className="labMembers">Post Baccalaureate Group Members</h3>
            </header>
            <section>
              <GroupMembers2 members={data.postBaccalaureate} />
            </section>
            <header>
              <h3 className="labMembers">Undergraduate Group Members</h3>
            </header>
            <section>
              <GroupMembers2 members={data.undergraduateStudents} />
            </section>
            <header>
              <h3 className="labMembers">Access Assistants</h3>
            </header>
            <section>
              <GroupMembersAccess members={data.accessAssistants} />
            </section>
          </div>
          <div className="alumniBG">
            <header>
              <h3 className="labMembers">Alumni</h3>
            </header>
            <section>
              <PrevMembers members={data.alumni} />
            </section>
          </div>
        </>
      ) : (
        <>
          <div className="groupHero">
            <header>
              <h3 className="labMembersTop">Group</h3>
            </header>
            <Row className="heroGroup">
              <Col>
                <Image
                  src="/images/Group/group4.png"
                  className="img-fluid groupPicHero"
                  alt="Group members all standing in a line with one holding a cake."
                />
              </Col>
            </Row>
          </div>
          <div className="groupContainer">
            <header>
              <h3 className="labMembers">Principal Investigator</h3>
            </header>
            <section>
              <Mona members={data.PI} />
            </section>
            <header>
              <h3 className="labMembers">PhD Group Members</h3>
            </header>
            <section>
              <GroupMembers members={data.PhDStudents} />
            </section>
            <header>
              <h3 className="labMembers">Post Baccalaureate Group Members</h3>
            </header>
            <section>
              <GroupMembers2 members={data.postBaccalaureate} />
            </section>
            <header>
              <h3 className="labMembers">Undergraduate Group Members</h3>
            </header>
            <section>
              <GroupMembers2 members={data.undergraduateStudents} />
            </section>
            <header>
              <h3 className="labMembers">Access Assistants</h3>
            </header>
            <section>
              <GroupMembersAccess members={data.accessAssistants} />
            </section>
          </div>
          <div className="alumniBG">
            <header>
              <h3 className="labMembers">Alumni</h3>
            </header>
            <section>
              <PrevMembers members={data.alumni} />
            </section>
          </div>
        </>
      )}
    </div>
  );
}
